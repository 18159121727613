<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="50%"
            :center="true"
            top="20vh"
            :close-on-click-modal="false"
            :before-close="() => closeStoreroomDialog('StoreroomEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="Storeroom"
                    :rules="rules"
                    ref="StoreroomEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type">
                        <el-form-item label="仓库名称" prop="name">
                            <el-input v-model="Storeroom.name" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="包含库区" prop="region">
                            <el-select
                                v-model="Storeroom.region"
                                placeholder="选择库区"
                                class="regionc"
                                filterable
                                clearable
                            >
                                <el-option
                                    v-for="reg in regions"
                                    :key="reg"
                                    :label="reg"
                                    :value="reg"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="仓库地址" prop="address">
                            <el-input type="textarea" :rows="2" v-model="Storeroom.address"></el-input>
                        </el-form-item>
                        <el-form-item label="仓库简介" prop="note">
                            <el-input type="textarea" :rows="5" v-model="Storeroom.note"></el-input>
                        </el-form-item>
                        <el-form-item label="所属企业" prop="enterprise_id">
                            <el-select
                                v-model="Storeroom.enterprise_id"
                                placeholder="选择企业"
                                @change="(e) => enterpriseChange(e)"
                                class="enterprisec"
                                filterable
                                clearable
                            >
                                <el-option
                                    v-for="enterprise in AllEnterprise"
                                    :key="enterprise.name"
                                    :label="enterprise.name"
                                    :value="enterprise.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <div v-if="this.id">
                            <el-form-item label="负责人" prop="person_id">
                                <el-select
                                    v-model="Storeroom.person_id"
                                    filterable
                                    placeholder="请选择负责人"
                                    class="personc"
                                >
                                    <el-option
                                        v-for="item in options"
                                        :key="item.name"
                                        :label="item.name"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="联系电话" prop="phone">
                                <el-input v-model="Storeroom.phone" clearable></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div v-else>
                        <el-descriptions
                            class="margin-top"
                            :column="2"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>仓库名称</span>
                                </template>
                                <span>{{Storeroom.name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>包含库区</span>
                                </template>
                                <span>{{Storeroom.region}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>负责人</span>
                                </template>
                                <span>{{Storeroom.person_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>联系电话</span>
                                </template>
                                <span>{{Storeroom.phone}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item :span="2">
                                <template slot="label">
                                    <span>所属企业</span>
                                </template>
                                <span>{{Storeroom.enterprise_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item :span="2">
                                <template slot="label">
                                    <span>仓库地址</span>
                                </template>
                                <span>{{Storeroom.address}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item :span="2" :contentStyle="{'text-align':'left'}">
                                <template slot="label">
                                    <span>仓库简介</span>
                                </template>
                                <span>{{Storeroom.note}}</span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="$_has(edit_auth)"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <el-button
                        v-if="$_has(del_auth)"
                        type="danger"
                        plain
                        @click="StoreroomDel('StoreroomEditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closeStoreroomDialog('StoreroomEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('StoreroomEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('StoreroomEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeStoreroomDialog('StoreroomEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('StoreroomEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="closeStoreroomDialog('StoreroomEditForm')"
                        >取消操作</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { storeroom_add_request,storeroom_del_request,storeroom_edit_request } from '@/network/WareHouse/storeroom.js'

export default {
    name: 'StoreroomEditComponent',
    data() {
        return {
            login_loading: false,
            Storeroom: {
                enterprise_id: '',
                enterprise_name: '',
                name: '',
                region: '',
                address: '',
                note: '',
                person_id: '',
                person_name: '',
                phone: ''
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入仓库名称',
                        trigger: 'blur',
                    },
                    {
                        min: 2,
                        max: 16,
                        message: '长度在 2 到 16 个字符',
                        trigger: 'blur',
                    },
                ],
                enterprise_id: [
                    {
                        required: true,
                        message: '请选择所属企业',
                        trigger: 'blur',
                    },
                ],
                address: [
                    {
                        required: true,
                        message: '请填写仓库地址',
                        trigger: 'blur',
                    },
                ],
                phone: [
                    {
                        pattern: /^1[3,5,6,7,8,9]\d{9}$/,
                        message: '手机号格式不正确',
                        trigger: 'blur',
                    },
                ],
            },
            options: [],
            regions: ['AB','CD','ABC','ABCD','ABCE']
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return '仓库详情'
            } else if (this.show_type === 1) {
                return '添加仓库'
            } else {
                return '修改仓库'
            }
        },
    },
    methods: {
        closeStoreroomDialog(formName) {
            this.Storeroom.region = ''
            this.Storeroom.enterprise_id = ''
            this.Storeroom.enterprise_name = ''
            this.Storeroom.person_id = ''
            this.Storeroom.person_name = ''
            this.$refs[formName].resetFields()
            this.$emit('exitStoreroomDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.StoreroomInsert(formName)
            })
        },
        StoreroomInsert(formName) {
            this.login_loading = true
            storeroom_add_request(this.Storeroom)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitStoreroomDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.StoreroomEdit(formName)
            })
        },
        StoreroomEdit(formName) {
            this.login_loading = true
            this.Storeroom.id = this.id
            storeroom_edit_request(this.Storeroom)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitStoreroomDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.Storeroom.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        StoreroomDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    storeroom_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeStoreroomDialog(formName))
                })
                .catch((err) => {})
        },
        enterpriseChange(e) {
            this.Storeroom.person_id = ''
            this.$emit('fenterpriseChange', e)
        },
    },
    filters: {},
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        storeroom_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        AllEnterprise: {
            type: Array,
            default() {
                return []
            },
        },
        foptions: {
            type: Array,
            default() {
                return []
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: String,
        del_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {
        storeroom_details_data(newVal) {
            this.Storeroom = newVal
        },
        foptions(newVal) {
            this.options = newVal
        },
    },
}
</script>

<style lang='less'>
.enterprisec,.personc {
    width: 100%;
}
.regionc {
    width: 100%;
}
.details>.el-form-item__label {
    color:cornflowerblue;
}
</style>