<template>
    <div class="storeroom_plan">
         <el-dialog
            title="仓库平面图"
            :visible.sync="PlanVisible"
            :append-to-body="true"
            width="90%"
            :center="true"
            top="2vh"
            :close-on-click-modal="false"
            :before-close="() => $emit('exitLocationPlanDialog')"
        >
            <el-row :gutter="10">
                <el-col class="have_border" :span=12>一号仓库</el-col>
                <el-col :span=2 style="text-align: center;"><p>道</p><p>路</p><p>纵</p><p>坡</p></el-col>
                <el-col class="have_border" :span=10>二号仓库</el-col>
            </el-row>
            <el-row style="height:20px;"></el-row>
            <el-row :gutter="10">
                <el-col class="have_border" :span=4>办公楼</el-col>
                <el-col :span=2 style="text-align: center;"><p>非</p><p>机</p><p>动</p><p>车</p><p>停</p><p>车</p><p>位</p></el-col>
                <el-col class="have_border" :span=18>三号仓库</el-col>
            </el-row>
            <el-row style="height:20px;"></el-row>
            <el-row :gutter="10">
                <el-col class="have_border have_heigth" :span="12">四号仓库</el-col>
            </el-row>
            <el-row style="height:20px;"></el-row>
            <el-row :gutter="10">
                <el-col class="have_border" :span="12">五号仓库</el-col>
            </el-row>
         </el-dialog>
    </div>
</template>

<script>
export default {
    name:'StoreroomPlanComponent',
    data(){
        return {}
    },
    computed:{},
    methods:{},
    filters:{},
    props:{
        PlanVisible: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    created(){},
    destoryed(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{}
}
</script>

<style>
.have_border {
    border: black 1px solid;
    height: 200px;
    line-height: 200px;
    text-align: center;
}
.have_heigth {
    height: 500px;
}
</style>