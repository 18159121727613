<template>
    <div v-loading.fullscreen.lock="loading">
        <storeroom-search-bar-component
            add_auth="新增仓库"
            :AllEnterprise="fAllEnterprise"
            @search="get_storeroom_index"
            @addTeam="addTeam"
            @ShowPlan="show_plan"
        ></storeroom-search-bar-component>
        <common-table-component
            details_auth="仓库详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_storeroom"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_storeroom_index"
        ></common-page-component>
        <storeroom-edit-component
            edit_auth="修改仓库"
            del_auth="删除仓库"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :AllEnterprise="fAllEnterprise"
            :foptions="foptions"
            :storeroom_details_data="fstoreroom_details_data"
            @exitStoreroomDialog="dialogExit"
            @search="get_storeroom_index"
            @fenterpriseChange="staff_list_request"
            @show_edit="show_edit"
            @details_row="details_storeroom"
        ></storeroom-edit-component>
        <storeroom-plan-component
            :PlanVisible="fPlanVisible"
            :data="plan_data"
            @exitLocationPlanDialog="PlanDialogExit"
        ></storeroom-plan-component>
    </div>
</template>

<script>
import { storeroom_index_request,storeroom_details_request } from '@/network/WareHouse/storeroom.js'
import { staff_list_request,enterprise_list_request } from '@/network/list.js'

import StoreroomSearchBarComponent from '@/components/WareHouse/Storeroom/StoreroomSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import StoreroomEditComponent from '@/components/WareHouse/Storeroom/StoreroomEditComponent'
import StoreroomPlanComponent from '@/components/WareHouse/Storeroom/StoreroomPlanComponent'

export default {
    name: 'StoreroomView',
    data() {
        return {
            plan_data:1,
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: true,
            ftotal: 0,
            cond: {
                name: '',
                enterprise_id: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'name',
                    label: '仓库名称',
                    minWidth: '120px'
                },{
                    prop: 'region',
                    label: '包含库区',
                    minWidth: '100px'
                },{
                    prop: 'enterprise_name',
                    label: '所属企业',
                    minWidth: '260px'
                },{
                    prop: 'person_name',
                    label: '负责人',
                    minWidth: '80px'
                }
            ],
            fstoreroom_details_data: {},
            foptions: [],
            fAllEnterprise: [],
            fPlanVisible: false
        }
    },
    computed: {},
    methods: {
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        get_storeroom_index(param = {}) {
            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.enterprise_id = param.enterprise_id ?? this.cond.enterprise_id
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            storeroom_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_storeroom(id) {
            this.fid = id
            this.fdialogFormVisible = true
            storeroom_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fstoreroom_details_data = s.result
                        //获取企业下的所有员工
                        staff_list_request({enterprise_id:s.result.enterprise_id})
                            .then((s) => {
                                this.foptions = s.result
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        staff_list_request(e) {
            staff_list_request({enterprise_id:e})
                .then((s) => {
                    this.foptions = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        get_all_enterprise() {
            this.loading = true
            enterprise_list_request()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.fAllEnterprise = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
        show_plan() {
            this.fPlanVisible = true
        },
        PlanDialogExit() {
            this.fPlanVisible = false
        },
    },
    filters: {},
    props: {},
    created() {
        this.get_storeroom_index()
        this.get_all_enterprise()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        StoreroomSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        StoreroomEditComponent,
        StoreroomPlanComponent
    },
    watch: {},
}
</script>

<style lang='less'></style>