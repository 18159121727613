<template>
    <el-card class="box-card">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="仓库名称" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="仓库名称搜索"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="所属企业" prop="model_id">
                <el-select
                    v-model="form.enterprise_id"
                    placeholder="选择企业"
                    class="enterprisec"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="enterprise in AllEnterprise"
                        :key="enterprise.name"
                        :label="enterprise.name"
                        :value="enterprise.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')"
                    >重置</el-button
                >
                <el-button type="success" plain @click="$emit('ShowPlan')">仓库平面图</el-button>
            </el-form-item>
            <el-button
                v-if="$_has(add_auth)"
                type="success"
                plain
                round
                @click="addTeam"
                class="temp_add"
                size="medium"
                >添加仓库</el-button
            >
        </el-form>
    </el-card>
</template>

<script>
export default {
    name: 'StoreroomSearchBarComponent',
    data() {
        return {
            fdialogFormVisible: false,
            form: {
                name: '',
                enterprise_id: '',
                page: 1,
            },
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.form.enterprise_id = ''
            this.$refs[f].resetFields()
        },
        addTeam() {
            this.$emit('addTeam')
        },
    },
    filters: {},
    props: {
        AllEnterprise: {
            type: Array,
            default() {
                return []
            },
        },
        add_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style>
.temp_add {
    float: right;
    margin-bottom: 10px;
}
</style>